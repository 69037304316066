var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-secondary', {
          attrs: {
            "itemTitle": "청약신청",
            "bg": "/images/form/application/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약신청")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("루미노816 청약신청 " + _vm._s(_vm.text))];
      },
      proxy: true
    }])
  }, [_c('form-input', {
    attrs: {
      "code": _vm.APPLICATION_TYPE.APPLICATION.value,
      "skin": "application"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }