<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-secondary itemTitle="청약신청" bg="/images/form/application/visual.jpg">
                <template #title>청약신청</template>
            </sub-head-secondary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>루미노816 청약신청 수정</template>

            <form-input :code="APPLICATION_TYPE.APPLICATION.value" skin="application" />

            <!-- S: 확인 팝업 -->
            <v-dialog width="588">
                <v-card tile flat class="py-40px py-md-60px py-lg-80px">
                    <txt-primary class="text-center">
                        등록 후 수정이 불가능합니다.<br />
                        내용을 다시 한번 확인 후 등록 바랍니다.<br />
                        등록하시겠습니까?
                    </txt-primary>
                    <div class="btn-wrap">
                        <v-row class="row--xs" justify="center">
                            <v-col cols="auto">
                                <v-btn x-large tile outlined class="w-160px" color="grey lighten-3">
                                    <span class="grey--text">취소</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn x-large tile class="w-160px white--text" color="black">등록</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-dialog>
            <!-- E: 확인 팝업 -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import { APPLICATION_TYPE } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import FormInput from "@/components/client/form/form-input.vue";

import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";
import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";

export default {
    components: {
        AppPrimary,
        SubHeadSecondary,
        PageSectionPrimary,
        FormInput,

        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
        SheetPrimary,
    },
    data(){
        return{
            APPLICATION_TYPE
        }
    }
};
</script>
