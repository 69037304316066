var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-secondary', {
          attrs: {
            "itemTitle": "입주관심등록",
            "bg": "/images/form/registration/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("입주관심등록")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("입주관심등록")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "page-section__content"
  }, [_c('sheet-quarternary', {
    staticClass: "text-center"
  }, [_c('txt-primary', {
    staticClass: "txt--lg"
  }, [_vm._v(" 계약·입주 포기 등으로 인하여 공실이 발생할 경우 예비입주자에게 우선 공급되며, "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 예비입주자가 모두 소진될 경우 입주관심 등록 고객께 입주상담 안내 등을 드릴 예정입니다. ")])], 1)], 1), _c('div', {
    staticClass: "page-section__content"
  }, [_c('form-input', {
    attrs: {
      "code": _vm.APPLICATION_TYPE.REGISTRATION.value,
      "skin": "registration"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }