var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-secondary', {
          attrs: {
            "itemTitle": "청약신청",
            "bg": "/images/form/application/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약신청")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("루미노816 청약신청 수정")];
      },
      proxy: true
    }])
  }, [_c('form-input', {
    attrs: {
      "code": _vm.APPLICATION_TYPE.APPLICATION.value,
      "skin": "application"
    }
  }), _c('v-dialog', {
    attrs: {
      "width": "588"
    }
  }, [_c('v-card', {
    staticClass: "py-40px py-md-60px py-lg-80px",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('txt-primary', {
    staticClass: "text-center"
  }, [_vm._v(" 등록 후 수정이 불가능합니다."), _c('br'), _vm._v(" 내용을 다시 한번 확인 후 등록 바랍니다."), _c('br'), _vm._v(" 등록하시겠습니까? ")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-160px",
    attrs: {
      "x-large": "",
      "tile": "",
      "outlined": "",
      "color": "grey lighten-3"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-160px white--text",
    attrs: {
      "x-large": "",
      "tile": "",
      "color": "black"
    }
  }, [_vm._v("등록")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }