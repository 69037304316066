<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-secondary itemTitle="청약신청" bg="/images/form/application/visual.jpg">
                <template #title>청약신청</template>
            </sub-head-secondary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>루미노816 청약신청 {{ text }}</template>

            <form-input :code="APPLICATION_TYPE.APPLICATION.value" skin="application" />
        </page-section-primary>
    </app-primary>
</template>

<script>
import { APPLICATION_TYPE } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import FormInput from "@/components/client/form/form-input.vue";

import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";
import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";

export default {
    components: {
        AppPrimary,
        SubHeadSecondary,
        PageSectionPrimary,
        FormInput,

        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
        SheetPrimary,
    },
    data(){
        return{
            APPLICATION_TYPE
        }
    },
    computed: {
        text() {
            return this.$route.query._certification ? "접수" : "수정";
        }
    }
};
</script>
